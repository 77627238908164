self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  throwOnUnhandled: false,
  workflow: [
    { handler: 'log', matchId: 'ember-string-loc' },
    { handler: 'silence', matchId: 'computed-property.override' },
    { handler: 'silence', matchId: 'ember-views.event-dispatcher.mouseenter-leave-move' },
    { handler: 'silence', matchId: 'action.mouseenter-leave-move' },
    { handler: 'silence', matchId: 'component.mouseenter-leave-move' },
    // Outstanding issues in torii:
    // * https://github.com/Vestorly/torii/pull/429
    // * https://github.com/Vestorly/torii/pull/427
    { handler: 'silence', matchId: 'ember-polyfills.deprecate-merge' },
    // https://github.com/pzuraq/liquid-wormhole/issues/69
    { handler: 'silence', matchId: 'ember-views.curly-components.jquery-element' },
    // https://github.com/miguelcobain/ember-paper/issues/1165
    { handler: 'silence', matchId: 'glimmer.private-in-element' },
    { handler: 'silence', matchId: 'ember-data:method-calls-on-destroyed-store' },
    { handler: 'silence', matchId: 'ember-utils.try-invoke' },
    { handler: 'silence', matchId: 'ember-data:model.toJSON' },
  ]
};
