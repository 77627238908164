define("ember-data-factory-guy/mocks/maybe-id-url-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   This is a mixin used by Mocks that might match on id or not depending if id exists
  
   @param superclass
   @constructor
   */
  const MaybeIdUrlMatch = superclass => class extends superclass {
    /**
     Used by getUrl to => this.get('id')
      For mockDelete: If the id is null, the url will not include the id, and
     can therefore be used to match any delete for this modelName
     */
    get(...args) {
      if (args[0] === 'id') {
        return this.id;
      }
    }

    /**
     *
     * @returns {String} url
     */
    getUrl() {
      let url = super.getUrl();
      if (!this.id) {
        url = `${url}/:id`;
      }
      return url;
    }
  };
  var _default = _exports.default = MaybeIdUrlMatch;
});