define("ember-power-calendar/components/power-calendar", ["exports", "ember-concurrency", "ember-power-calendar/templates/components/power-calendar", "ember-power-calendar-utils"], function (_exports, _emberConcurrency, _powerCalendar, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _powerCalendar.default,
    classNames: ['ember-power-calendar'],
    powerCalendarService: Ember.inject.service('power-calendar'),
    navComponent: 'power-calendar/nav',
    daysComponent: 'power-calendar/days',
    center: null,
    _calendarType: 'single',
    // Lifecycle chooks
    init() {
      this._super(...arguments);
      this.registerCalendar();
      let onInit = this.get('onInit');
      if (onInit) {
        onInit(this.get('publicAPI'));
      }
    },
    willDestroy() {
      this._super(...arguments);
      this.unregisterCalendar();
    },
    // CPs
    publicActions: Ember.computed('onSelect', 'onCenterChange', function () {
      let actions = {};
      if (this.get('onSelect')) {
        actions.select = (...args) => this.send('select', ...args);
      }
      if (this.get('onCenterChange')) {
        let changeCenter = (newCenter, calendar, e) => {
          return this.get('changeCenterTask').perform(newCenter, calendar, e);
        };
        actions.changeCenter = changeCenter;
        actions.moveCenter = (step, unit, calendar, e) => {
          let newCenter = (0, _emberPowerCalendarUtils.add)(this.get('currentCenter'), step, unit);
          return changeCenter(newCenter, calendar, e);
        };
      }
      return actions;
    }),
    selected: Ember.computed({
      get() {
        return undefined;
      },
      set(_, v) {
        return (0, _emberPowerCalendarUtils.normalizeDate)(v);
      }
    }),
    currentCenter: Ember.computed('center', function () {
      let center = this.get('center');
      if (!center) {
        center = this.get('selected') || this.get('powerCalendarService').getDate();
      }
      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),
    publicAPI: Ember.computed('_publicAPI', function () {
      return this.get('_publicAPI');
    }),
    _publicAPI: Ember.computed('selected', 'currentCenter', 'locale', 'powerCalendarService.locale', 'changeCenterTask.isRunning', 'publicActions', function () {
      return {
        uniqueId: Ember.guidFor(this),
        type: this.get('_calendarType'),
        selected: this.get('selected'),
        loading: this.get('changeCenterTask.isRunning'),
        center: this.get('currentCenter'),
        locale: this.get('locale') || this.get('powerCalendarService.locale'),
        actions: this.get('publicActions')
      };
    }),
    // Actions
    actions: {
      select(day, calendar, e) {
        let action = this.get('onSelect');
        if (action) {
          action(day, calendar, e);
        }
      }
    },
    // Tasks
    changeCenterTask: (0, _emberConcurrency.task)(function* (newCenter, calendar, e) {
      let action = this.get('onCenterChange');
      (true && !(typeof action === 'function') && Ember.assert('You attempted to move the center of a calendar that doesn\'t receive an `onCenterChange` action.', typeof action === 'function'));
      let value = (0, _emberPowerCalendarUtils.normalizeCalendarValue)({
        date: newCenter
      });
      yield action(value, calendar, e);
    }),
    // Methods
    registerCalendar() {
      if (window) {
        window.__powerCalendars = window.__powerCalendars || {}; // TODO: weakmap??
        window.__powerCalendars[Ember.guidFor(this)] = this;
      }
    },
    unregisterCalendar() {
      if (window) {
        delete window.__powerCalendars[Ember.guidFor(this)];
      }
    }
  });
});