define("ember-metrics/metrics-adapters/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function makeToString(ret) {
    return () => ret;
  }
  class BaseAdapter extends Ember.Object {
    constructor(config, owner) {
      super(...arguments);
      _defineProperty(this, "metrics", null);
      _defineProperty(this, "config", null);
      Ember.setOwner(this, owner);
      this.config = config;
      this.init();
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      (true && !(false) && Ember.assert(`[ember-metrics] ${this.toString()} must implement the init hook!`));
    }
    willDestroy() {
      (true && !(false) && Ember.assert(`[ember-metrics] ${this.toString()} must implement the willDestroy hook!`));
    }
    get() {
      (true && !(false) && Ember.deprecate('Metrics Adapters must not use EmberObject methods as they will be implemented as native classes in the next major release', false, {
        id: 'ember-metrics-issue-287',
        for: 'ember-metrics',
        url: 'https://github.com/adopted-ember-addons/ember-metrics/issues/287',
        since: '1.4.0',
        until: '2.0.0'
      }));
      super.get(...arguments);
    }
    set() {
      (true && !(false) && Ember.deprecate('Metrics Adapters must not use EmberObject methods as they will be implemented as native classes in the next major release', false, {
        id: 'ember-metrics.issue-287',
        for: 'ember-metrics',
        url: 'https://github.com/adopted-ember-addons/ember-metrics/issues/287',
        since: '1.4.0',
        until: '2.0.0'
      }));
      super.set(...arguments);
    }
    toString() {
      const hasToStringExtension = Ember.typeOf(this.toStringExtension) === 'function';
      const extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      const ret = `ember-metrics@metrics-adapter:${extension}:${Ember.guidFor(this)}`;
      this.toString = makeToString(ret);
      return ret;
    }
    identify() {}
    trackEvent() {}
    trackPage() {}
    alias() {}
  }
  _exports.default = BaseAdapter;
  _defineProperty(BaseAdapter, "supportsFastBoot", false);
});