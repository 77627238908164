define("ember-data-factory-guy/mocks/mock-any-request", ["exports", "ember-data-factory-guy/mocks/mock-request", "ember-data-factory-guy/utils/helper-functions"], function (_exports, _mockRequest, _helperFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockAnyRequest extends _mockRequest.default {
    constructor({
      type = 'GET',
      url,
      responseText,
      status = 200
    }) {
      super();
      this.responseJson = responseText;
      if (this.isErrorStatus(status)) this.errorResponse = responseText;
      this.url = url;
      this.type = type;
      this.status = status;
      this.setupHandler();
    }
    getUrl() {
      return this.url;
    }
    getType() {
      return this.type;
    }

    /**
     * Return some form of object
     *
     * @param json
     * @returns {*}
     */
    returns(json) {
      this.responseJson = json;
      return this;
    }
    paramsMatch(request) {
      if (!(0, _helperFunctions.isEmptyObject)(this.someQueryParams)) {
        return this._tryMatchParams(request, this.someQueryParams, _helperFunctions.isPartOf);
      }
      if (!(0, _helperFunctions.isEmptyObject)(this.queryParams)) {
        return this._tryMatchParams(request, this.queryParams, _helperFunctions.isEquivalent);
      }
      return true;
    }
    _tryMatchParams(request, handlerParams, comparisonFunction) {
      let requestParams = request.queryParams;
      if (/POST|PUT|PATCH/.test(this.type)) {
        requestParams = (0, _helperFunctions.paramsFromRequestBody)(request.requestBody);
      }
      return comparisonFunction((0, _helperFunctions.toParams)(requestParams), (0, _helperFunctions.toParams)(handlerParams));
    }
  }
  _exports.default = MockAnyRequest;
});