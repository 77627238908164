define("ember-data-factory-guy/mocks/mock-links-request", ["exports", "ember-data-factory-guy/mocks/mock-request", "ember-data-factory-guy/factory-guy", "ember-data/model", "ember-data-factory-guy/utils/helper-functions"], function (_exports, _mockRequest, _factoryGuy, _model, _helperFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockLinksRequest extends _mockRequest.default {
    constructor(model, relationshipKey) {
      super();
      this.model = model;
      this.relationshipKey = relationshipKey;
      this.relationship = this.getRelationship();
      this.fixtureBuilder = _factoryGuy.default.fixtureBuilder(this.relationship.type);
      this.setValidReturnsKeys();
      this.type = 'GET';
      this.status = 200;
      this.extractUrl();
      this.setupHandler();
    }
    getRelationship() {
      let modelClass = this.model.constructor,
        relationships = Ember.get(modelClass, 'relationshipsByName'),
        relationship = relationships.get(this.relationshipKey);
      (true && !(relationship) && Ember.assert(`[ember-data-factory-guy] mockLinks can not find that relationship 
        [${this.relationshipKey}] on model of type ${modelClass.modelName}`, relationship));
      return relationship;
    }
    setValidReturnsKeys() {
      const isBelongsTo = this.relationship.kind === 'belongsTo',
        validKeys = isBelongsTo ? ['model', 'json'] : ['models', 'json'];
      this.validReturnsKeys = validKeys;
    }

    /**
     * Links url's could have query parameters, and this extraction will pull
     * that apart into the url and query parameters so pretender can make a match
     */
    extractUrl() {
      const url = this.model[this.relationship.kind](this.relationshipKey).link();
      const [urlPart, queryParams] = (0, _helperFunctions.parseUrl)(url);
      this.withParams(queryParams);
      this.url = urlPart;
    }
    getUrl() {
      return this.url;
    }
    getType() {
      return this.type;
    }
    validateReturnsOptions(options) {
      const responseKeys = Object.keys(options);
      (true && !(responseKeys.length === 1) && Ember.assert(`[ember-data-factory-guy] You can pass one key to 'returns',
                you passed these keys: ${responseKeys}`, responseKeys.length === 1));
      const [responseKey] = responseKeys;
      (true && !(this.validReturnsKeys.includes(responseKey)) && Ember.assert(`[ember-data-factory-guy] You passed an invalid keys for 'returns' function.
      Valid keys are ${this.validReturnsKeys}. You used this invalid key: ${responseKey}`, this.validReturnsKeys.includes(responseKey)));
      return responseKey;
    }
    returns(options = {}) {
      let responseKey = this.validateReturnsOptions(options);
      this.setReturns(responseKey, options);
      return this;
    }
    setReturns(responseKey, options) {
      let json,
        model,
        models,
        modelName = this.relationship.type;
      switch (responseKey) {
        case 'id':
          {
            // if you want to return existing model with an id, set up the json
            // as if it might be found, but check later during request match to
            // see if it really exists
            json = {
              id: options.id
            };
            this.idSearch = true;
            this.setResponseJson(this.fixtureBuilder.convertForBuild(modelName, json));
            break;
          }
        case 'model':
          model = options.model;
          (true && !(model instanceof _model.default) && Ember.assert(`[ember-data-factory-guy] argument ( model ) must be a Model instance - found type:'
          ${Ember.typeOf(model)}`, model instanceof _model.default));
          json = {
            id: model.id
          };
          this.setResponseJson(this.fixtureBuilder.convertForBuild(modelName, json));
          break;
        case 'ids':
          {
            const store = _factoryGuy.default.store;
            models = options.ids.map(id => store.peekRecord(modelName, id));
            this.returns({
              models
            });
            break;
          }
        case 'models':
          {
            models = options.models;
            (true && !(Ember.isArray(models)) && Ember.assert(`[ember-data-factory-guy] argument ( models ) must be an array - found type:'
          ${Ember.typeOf(models)}`, Ember.isArray(models)));
            json = models.map(model => ({
              id: model.id
            }));
            json = this.fixtureBuilder.convertForBuild(modelName, json);
            this.setResponseJson(json);
            break;
          }
        case 'json':
          json = options.json;
          if (!json.isProxy) {
            // need to wrap a payload in proxy class if not already done so
            this.fixtureBuilder.wrapPayload(modelName, json);
          }
          this.setResponseJson(json);
          break;
        case 'headers':
          this.addResponseHeaders(options.headers);
          break;
      }
    }
    setResponseJson(json) {
      this.responseJson = json;
      this.setupHandler();
    }
  }
  _exports.default = MockLinksRequest;
});