define("ember-data-factory-guy/mocks/mock-delete-request", ["exports", "ember-data-factory-guy/mocks/mock-store-request", "ember-data-factory-guy/mocks/maybe-id-url-match"], function (_exports, _mockStoreRequest, _maybeIdUrlMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockDeleteRequest extends (0, _maybeIdUrlMatch.default)(_mockStoreRequest.default) {
    constructor(modelName, {
      id,
      model
    } = {}) {
      super(modelName, 'deleteRecord');
      this.id = id;
      this.model = model;
      this.setupHandler();
    }
    getType() {
      return "DELETE";
    }
  }
  _exports.default = MockDeleteRequest;
});