define("ember-data-factory-guy/mocks/mock-create-request", ["exports", "ember-data-factory-guy/factory-guy", "ember-data-factory-guy/mocks/mock-store-request", "ember-data-factory-guy/mocks/attribute-matcher"], function (_exports, _factoryGuy, _mockStoreRequest, _attributeMatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockCreateRequest extends (0, _attributeMatcher.default)(_mockStoreRequest.default) {
    constructor(modelName, {
      model
    } = {}) {
      super(modelName, 'createRecord');
      this.model = model;
      this.returnArgs = {};
      this.matchArgs = {};
      this.setupHandler();
    }
    getType() {
      return "POST";
    }

    /**
     This returns only accepts attrs key
     These attrs are those attributes or relationships that
     you would like returned with the model when the create succeeds.
      @param {Object} returns attributes and or relationships to return with payload
     */
    returns(returns) {
      this.validateReturnsOptions(returns);
      this.returnArgs = returns.attrs;
      return this;
    }

    /**
     Unless the id is setup already in the return args, then setup a new id.
     */
    modelId() {
      let returnArgs = this.returnArgs;
      if (Ember.isPresent(returnArgs) && Ember.isPresent(returnArgs['id'])) {
        return returnArgs['id'];
      } else {
        let definition = _factoryGuy.default.findModelDefinition(this.modelName);
        return definition.nextId();
      }
    }

    /**
     This mock might be called a few times in a row so,
     Need to clone the responseJson and add id at the very last minute
     */
    getResponse() {
      let args = Ember.assign({}, this.matchArgs, this.returnArgs),
        json = Ember.assign({}, args, {
          id: this.modelId()
        });
      this.responseJson = this.fixtureBuilder.convertForBuild(this.modelName, json);
      return super.getResponse();
    }
  }
  _exports.default = MockCreateRequest;
});