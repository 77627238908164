define("ember-data-factory-guy/payload/rest-payload", ["exports", "ember-data-factory-guy/payload/base-payload"], function (_exports, _basePayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _basePayload.default {
    constructor(modelName, json, converter) {
      super(modelName, json, converter);
      this.payloadKey = converter.getPayloadKey(modelName);
      this.addProxyMethods(['includeKeys', 'getInclude']);
    }
    includeKeys() {
      let keys = Ember.A(Object.keys(this.json)).reject(key => this.payloadKey === key);
      return Ember.A(keys).reject(key => Ember.A(this.proxyMethods).includes(key)) || [];
    }
    getInclude(modelType) {
      return this.json[modelType];
    }
    getObjectKeys(key) {
      let attrs = this.json[this.payloadKey];
      if (Ember.isEmpty(key)) {
        return attrs;
      }
      return attrs[key];
    }
    getListKeys(key) {
      let attrs = this.json[this.payloadKey];
      if (Ember.isEmpty(key)) {
        return attrs;
      }
      if (typeof key === 'number') {
        return attrs[key];
      }
      if (key === 'firstObject') {
        return attrs[0];
      }
      if (key === 'lastObject') {
        return attrs[attrs.length - 1];
      }
    }
  }
  _exports.default = _default;
});