define("ember-data-factory-guy/scenario", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var fgMethods = _emberDataFactoryGuy;
  let proxyFx = ['make', 'makeNew', 'makeList', 'build', 'buildList', 'mockFind', 'mockFindRecord', 'mockFindAll', 'mockReload', 'mockQuery', 'mockQueryRecord', 'mockUpdate', 'mockCreate', 'mockDelete', 'mock', 'attributesFor'];
  class _default {
    constructor() {
      proxyFx.forEach(fx => this[fx] = fgMethods[fx]);
      this.store = _emberDataFactoryGuy.default.store;
    }
    static settings(opts = {}) {
      _emberDataFactoryGuy.default.settings(opts);
    }
    run() {}
    include(scenarios) {
      (scenarios || []).forEach(Scenario => new Scenario().run());
    }
  }
  _exports.default = _default;
});